import { DateTime } from "luxon";
import { SimulatorReservation } from "Models";

export type DateTimeRange = {
  date: TodayOrTomorrow;
  startTime: string;
  endTime: string;
};

export enum SimulatorReservationActions {
  EDIT = "Edit",
  DELETE = "Delete",
  SEND_TO_SIM = "Send",
  CLEAR_ID = "Clear",
}

export enum TodayOrTomorrow {
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
}

export const simulatorReservationIsWithinTimeRange = (
  simulatorReservation: SimulatorReservation,
  startTime: string,
  endTime: string
): boolean => {
  return (
    DateTime.fromISO(simulatorReservation.startTime) >
      DateTime.fromISO(startTime) &&
    DateTime.fromISO(simulatorReservation.startTime) < DateTime.fromISO(endTime)
  );
};

export const dateTimeRanges = {
  today: (): DateTimeRange => {
    return {
      date: TodayOrTomorrow.TODAY,
      startTime: DateTime.local()
        .startOf("day")
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
      endTime: DateTime.local()
        .endOf("day")
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
    };
  },
  tomorrow: (): DateTimeRange => {
    return {
      date: TodayOrTomorrow.TOMORROW,
      startTime: DateTime.local()
        .startOf("day")
        .plus({ days: 1 })
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
      endTime: DateTime.local()
        .endOf("day")
        .plus({ days: 1 })
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
    };
  },
};

import { Hole, SimulatorDto } from "Models";
import { useFacilityCourses } from "modules/course/courseHooks";
import { useFormContext } from "react-hook-form";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { getSimulatorsByIdArray } from "modules/simulator/simulatorSelectors";
import LeagueRoundCourseAndHolesForm from "./LeagueRoundCourseAndHolesForm";
import MissingSimsList from "components/control/round-control/round-configuration-form/facility/MissingSimsList";

const LeagueRoundCourseAndHoles: React.FC = () => {
  const { watch } = useFormContext();
  const watchCourses = watch("course");

  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [queryCourses] = useFacilityCourses(currentFacilityId);

  const selectedCourseHoles: Hole[] = useSelector(
    (state: RootState) =>
      state.entities.courses?.courseData?.[watchCourses]?.holes || []
  );

  const missingSimIds: string[] = useSelector(
    (state: RootState) =>
      state.entities.courses?.missingSimulatorsByCourse?.[watchCourses] || []
  );

  const selectedCourseMissingSims: SimulatorDto[] = useSelector(
    (state: RootState) => getSimulatorsByIdArray(state, missingSimIds)
  );

  return (
    <div>
      {selectedCourseMissingSims.length ? (
        <MissingSimsList missingSimulators={selectedCourseMissingSims} />
      ) : null}
      <LeagueRoundCourseAndHolesForm
        courses={queryCourses}
        holes={selectedCourseHoles}
      />
    </div>
  );
};

export default LeagueRoundCourseAndHoles;

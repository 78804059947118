import { useState } from "react";
import { RootState } from "StoreTypes";
import { styles } from "./admin.styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { clubhouseApiHeadersV2 } from "utils/api/apiHelper";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  getUserIdFromToken,
  useFacilitiesByUserId,
} from "modules/facility/facilityHooks";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import axios from "axios";
import Tippy from "@tippyjs/react";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";

const FacilityManagementModal: React.FC<FacilityManagementModalProps> = (
  props
) => {
  const dispatch = useAppDispatch();

  const currentFacility = useSelector((state: RootState) =>
    getCurrentFacility(state)
  );

  const userId = () => getUserIdFromToken();
  const [, , refreshFacilities] = useFacilitiesByUserId(userId());

  const [updateFacility, setUpdateFacility] = useState(false);
  const [facilitySettings, setFacilitySettings] = useState({
    reservationNotification:
      currentFacility?.settings?.reservationNotification || 15,
    adHocWarning: currentFacility?.settings?.adHocWarning || 30,
    screenShotNotification:
      currentFacility?.settings?.screenShotNotification || 450,
    screenShotCacheLimit: currentFacility?.settings?.screenShotCacheLimit || 45,
  });

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.SimManagementModal,
      })
    );
  };

  const handleSaveChanges = async () => {
    async function updateFacility() {
      const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${currentFacility.id}/settings`;
      axios
        .patch(url, facilitySettings, clubhouseApiHeadersV2())
        .then(async () => {
          pendoTrackEventWithProperties(ClubhouseEvents.UPDATE_FACILITY, {
            id: currentFacility.id,
            screenShotOn: false,
          });
          await refreshFacilities();
        });
    }
    if (updateFacility) {
      await updateFacility();
      await refreshFacilities();
      await setUpdateFacility(false);
    }
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`${currentFacility?.name} Facility Management`}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <div className="mb-4">
            <div className="text-xs font-normal mb-1"></div>
            <div className="text-sm  font-normal">
              <span className="text-gray-500 dark:text-gray-50">
                Update settings below and click 'Save Changes' to apply changes.
              </span>
            </div>
          </div>

          <div className="my-8">
            <div className={styles.facilitySettingsRow}>
              <div className={styles.facilitySettingsColumnOne}>
                Start Game Warning
                <Tippy
                  content="This is a warning notification that will show when sending rounds to a simulator if that simulator has an upcoming reservation starting within X minutes."
                  className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 p-2 border-2 rounded float-right inline"
                  placement="auto"
                >
                  <div>
                    <IoMdInformationCircleOutline className="size-4 text-zinc-600 dark:text-gray-50 ml-2" />
                  </div>
                </Tippy>
              </div>
              <div className={styles.facilitySettingsColumnTwo}>
                <input
                  type="number"
                  className={styles.facilitySettingsInputNumber}
                  defaultValue={facilitySettings.adHocWarning}
                  autoFocus
                  min={0}
                  step={1}
                  onChange={(e) => {
                    setFacilitySettings({
                      ...facilitySettings,
                      adHocWarning: Number(e.target.value),
                    });
                    setUpdateFacility(true);
                  }}
                />
                <div className={styles.span}>
                  minutes before next reservation a warning is shown.
                </div>
              </div>
            </div>
            <div className={styles.facilitySettingsRow}>
              <div className={styles.facilitySettingsColumnOne}>
                Reservation Notification
                <Tippy
                  content="This will cause a notification to appear on the simulator card if a reservation is scheduled to start on that simulator within X minutes."
                  className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 p-2 border-2 rounded float-right inline"
                  placement="auto"
                >
                  <div>
                    <IoMdInformationCircleOutline className="size-4 text-zinc-600 dark:text-gray-50 ml-2" />
                  </div>
                </Tippy>
              </div>
              <div className={styles.facilitySettingsColumnTwo}>
                <input
                  type="number"
                  className={styles.facilitySettingsInputNumber}
                  defaultValue={facilitySettings.reservationNotification}
                  min={0}
                  step={1}
                  onChange={(e) => {
                    setFacilitySettings({
                      ...facilitySettings,
                      reservationNotification: Number(e.target.value),
                    });
                    setUpdateFacility(true);
                  }}
                />
                <div className={styles.span}>
                  minutes before next reservation a notification is shown.
                </div>
              </div>
            </div>
            <div className={`${styles.facilitySettingsRow} hidden`}>
              <div className={styles.facilitySettingsColumnOne}>
                Screenshot Cache Limit
                <Tippy
                  content="This tells the system how long to store the latest screen shot image in minutes."
                  className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 p-2 border-2 rounded float-right inline"
                  placement="auto"
                >
                  <div>
                    <IoMdInformationCircleOutline className="size-4 text-zinc-600 dark:text-gray-50 ml-2" />
                  </div>
                </Tippy>
              </div>
              <div className={styles.facilitySettingsColumnTwo}>
                <input
                  type="number"
                  className={styles.facilitySettingsInputNumber}
                  defaultValue={facilitySettings.screenShotCacheLimit}
                  min={0}
                  step={1}
                  onChange={(e) => {
                    setFacilitySettings({
                      ...facilitySettings,
                      screenShotCacheLimit: Number(e.target.value),
                    });
                    setUpdateFacility(true);
                  }}
                />
                <div className={styles.span}>
                  minutes before next reservation a warning is shown.
                </div>
              </div>
            </div>
            <div className={`${styles.facilitySettingsRow}`}>
              <div className={styles.facilitySettingsColumnOne}>
                Screenshot Notification
                <Tippy
                  content="While screenshots are active, if a screenshot isn’t received within X seconds, a notification will appear so you can investigate if the simulator is “stuck”."
                  className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 p-2 border-2 rounded float-right inline"
                  placement="auto"
                >
                  <div>
                    <IoMdInformationCircleOutline className="size-4 text-zinc-600 dark:text-gray-50 ml-2" />
                  </div>
                </Tippy>
              </div>
              <div className={styles.facilitySettingsColumnTwo}>
                <input
                  type="number"
                  className={styles.facilitySettingsInputNumber}
                  defaultValue={facilitySettings.screenShotNotification}
                  min={0}
                  step={1}
                  onChange={(e) => {
                    setFacilitySettings({
                      ...facilitySettings,
                      screenShotNotification: Number(e.target.value),
                    });
                    setUpdateFacility(true);
                  }}
                />
                <div className={styles.span}>
                  elapsed seconds between captures before a warning is raised.
                </div>
              </div>
            </div>
            <button
              className="w-full bg-brandYellow text-black py-2 rounded-md mb-4 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-400 mt-8"
              onClick={() => handleSaveChanges()}
              disabled={!updateFacility}
            >
              Save Changes
            </button>
          </div>
        </Row>
      }
    />
  );
};

type FacilityManagementModalProps = {
  title?: string;
  modalName: string;
};

FacilityManagementModal.defaultProps = {
  title: "Sim Management",
};

export default FacilityManagementModal;

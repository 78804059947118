import { AnimatePresence, motion } from "framer-motion";
import { isModalOpen } from "utils/modals/modalHelpers";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { SimulatorState } from "Models";
import { MdClose } from "react-icons/md";
import SimSendMessageSmall from "components/shared/control/SimSendMessageSmall";

const SendMessageInnerModal: React.FC<SendMessageInnerModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const { simulatorState, modalName } = props;

  const setModalClose = () => {
    dispatch(modalAction({ isOpen: false, modalName: modalName }));
  };

  return (
    <>
      <AnimatePresence>
        {isModalOpen(modalName) && (
          <motion.div
            className="z-9999 h-full w-full border-1 rounded-xl border-gray-400 absolute bg-white dark:bg-zinc-800"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{
              width: 0,
              opacity: 0.7,
              transition: { delay: 0.15, duration: 0.3 },
            }}
            key={`sendMessageInnerModal-${modalName}`}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={`addSendMessageModalHeader-${modalName}`}
            >
              <div className="relative flex flex-row w-full items-end justify-end pr-4 mt-2 overflow-hidden h-6 pt-1 mr-2">
                <button
                  onClick={() => setModalClose()}
                  className="z-10 flex gap-2 font-bold right-0 top-1 w-6 h-6"
                >
                  <MdClose className="w-6 h-6 text-black dark:text-white" />
                </button>
              </div>
              <div className="flex flex-col px-4 pb-2">
                <SimSendMessageSmall simulatorId={simulatorState.id} />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default SendMessageInnerModal;

type SendMessageInnerModalProps = {
  simulatorState: SimulatorState;
  modalName: string;
};

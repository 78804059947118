import { DateTime } from "luxon";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { SimulatorReservation } from "Models";
import { setSelectedQueueItem } from "ui-modules/queue/queueSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { getCurrentFacilitySettings } from "modules/facility/facilitySelectors";
import { useSimulatorReservationsByFacilityAndSimId } from "modules/simulator-reservations/simulatorReservationHooks";

type UpcomingReservationsProps = {
  simulatorId?: string;
};

const UpcomingReservations: React.FC<UpcomingReservationsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { simulatorId } = props;

  const [upcomingReservations, setUpcomingReservations] = useState<
    SimulatorReservation[]
  >([]);

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const leadTime = useSelector((state: RootState) =>
    getCurrentFacilitySettings(state)
  )?.adHocWarning;

  const facilitySimulatorReservations = useSimulatorReservationsByFacilityAndSimId(
    currentFacilityId,
    simulatorId || ""
  );

  const handleEditSimulatorReservation = (queueItemId: string) => {
    dispatch(
      setSelectedQueueItem({
        selectedQueueItem: queueItemId,
      })
    );
    dispatch(modalAction({ isOpen: true, modalName: ModalType.QueueModal }));

    pendoTrackBasicEvent(ClubhouseEvents.EDIT_RESERVATION, queueItemId);
  };

  useEffect(() => {
    const filteredReservations = facilitySimulatorReservations
      .filter((reservation) => {
        return (
          DateTime.fromISO(reservation.startTime, { setZone: true }) >
            DateTime.now().minus({ minutes: leadTime }) &&
          DateTime.fromISO(reservation.startTime, { setZone: true }) >
            DateTime.now()
        );
      })
      .sort(
        (a: SimulatorReservation, b: SimulatorReservation) =>
          new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf()
      );

    const interval = setInterval(() => {
      setUpcomingReservations(filteredReservations);
    }, 20000);

    return () => clearInterval(interval);
  }, [facilitySimulatorReservations, leadTime]);

  return (
    <div className="flex">
      {upcomingReservations?.length > 0 && (
        <button
          className="flex bg-brandYellow hover:bg-brandGreen dark:text-black text-black hover:text-white rounded-lg h-6 px-4 py-1"
          onClick={() =>
            handleEditSimulatorReservation(upcomingReservations[0].id)
          }
        >
          Reservation @{" "}
          {DateTime.fromISO(
            upcomingReservations[0]?.startTime.toString()
          ).toLocaleString(DateTime.TIME_SIMPLE)}
        </button>
      )}
    </div>
  );
};

export default UpcomingReservations;

import { useState } from "react";
import { SimulatorDto } from "Models";
import { styles } from "./admin.styles";
import { FiEdit3 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { MdOutlineDragHandle } from "react-icons/md";
import { modalAction } from "ui-modules/modals/modalSlice";
import { useFacilitySimulatorsPatch } from "modules/simulator/simulatorHooks";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import {
  getUserIdFromToken,
  useFacilitiesByUserId,
  useGetCurrentLocalFacilityId,
} from "modules/facility/facilityHooks";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import arrayMove from "array-move";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import SpinnerAnimation from "components/svg/SpinnerAnimation";

const SimManagementModal: React.FC<SimManagementModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const simulators = useSelector(getSimulatorsByCurrentFacility);

  const userId = () => getUserIdFromToken();
  const [, , refreshFacilities] = useFacilitiesByUserId(userId());

  const [items, setItems] = useState(simulators);
  const [updateSims, setUpdateSims] = useState(false);

  const [
    updateFacilitySimulatorsQueryState,
    updateFacilitySimulators,
  ] = useFacilitySimulatorsPatch(currentFacilityId);

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.SimManagementModal,
      })
    );
  };

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
    setUpdateSims(true);
  };

  const handleSaveChanges = async () => {
    async function updateSimulators() {
      let payload: { id: string; name: string }[] = items.map((item) => {
        return {
          id: item.id,
          name: item.name,
          screenShotOn: item.status.screenShotOn,
        };
      });

      await updateFacilitySimulators(payload);
    }
    if (updateSims) {
      await updateSimulators();
      await refreshFacilities();
      await setUpdateSims(false);
    }
  };

  const SimulatorItem = ({ item }: { item: SimulatorDto }) => {
    const [editName, setEditName] = useState(false);
    const [simulatorName, setSimulatorName] = useState(item.name);

    const handleNameChange = () => {
      async function updateSimulatorName() {
        if (item.name !== simulatorName) {
          setItems((prevItems) => {
            return prevItems.map((sim) => {
              if (sim.id === item.id) {
                return { ...sim, name: simulatorName };
              }
              return sim;
            });
          });
          setUpdateSims(true);
        }
      }
      updateSimulatorName();
      setEditName(false);
    };

    const handleScreenshotChange = (checked: boolean) => {
      setItems((prevItems) => {
        return prevItems.map((sim) => {
          if (sim.id === item.id) {
            return {
              ...sim,
              status: {
                ...sim.status,
                screenShotOn: checked,
              },
            };
          }
          return sim;
        });
      });
      setUpdateSims(true);
    };

    return (
      <div className="flex flex-1 flex-col">
        <div className="flex">
          {editName ? (
            <div className="flex justify-start items-center">
              <input
                type="text"
                className="border border-gray-300 h-6 m-0 px-2 w-auto rounded-md text-xs"
                placeholder={item.name}
                autoFocus
                onChange={(e) => setSimulatorName(e.target.value)}
              />
              <button
                className={styles.editLinks}
                onClick={() => handleNameChange()}
              >
                save
              </button>

              <button
                className={styles.editLinks}
                onClick={() => setEditName(false)}
              >
                cancel
              </button>
            </div>
          ) : (
            item?.name
          )}
          {!editName && (
            <button
              className={styles.editLinks}
              onClick={() => setEditName(true)}
            >
              rename
            </button>
          )}
        </div>
        <div className="flex text-xs mt-3 text-gray-400 dark:text-black">
          {item.capabilities.canTurnOnScreenShots ? (
            <>
              Show live screenshots
              <input
                type="checkbox"
                className="border-gray-300 rounded disabled:bg-gray-300 dark:disabled:bg-zinc-900 text-brandGreen focus:ring-0 ring-opacity-0 border ml-2"
                onChange={(e) => handleScreenshotChange(e.target.checked)}
                checked={item.status.screenShotOn}
              />
            </>
          ) : (
            <>Live screenshots unavaible</>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Sim Management`}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <div className="mb-4">
            <div className="text-xs font-normal mb-1">
              <MdOutlineDragHandle className="size-4  mr-2" />
              <span className="text-gray-500">
                Drag simulator cards to reorder how they appear on the dashboard
              </span>
            </div>
            <div className="text-xs font-normal">
              <FiEdit3 className="size-4 mr-2" />
              <span className="text-gray-500">
                Click edit to rename simulator
              </span>
            </div>
          </div>
          <button
            className="w-full bg-brandYellow text-black py-2 rounded-md mb-4 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={() => handleSaveChanges()}
            disabled={!updateSims}
          >
            Save Changes
          </button>
          {updateFacilitySimulatorsQueryState.isPending ? (
            <div className={styles.spinnerContainer}>
              <SpinnerAnimation color={"#000"} />
            </div>
          ) : (
            <SortableList
              onSortEnd={handleSortEnd}
              className={styles.sortedList}
              draggedItemClassName="draggedItemClass"
            >
              {items.map((item: SimulatorDto) => (
                <SortableItem key={item.id}>
                  <div className={styles.simulatorSortCardContainer}>
                    <SimulatorItem item={item} key={item.id} />
                    <div className={styles.sortGrab}>
                      <SortableKnob key={item.id}>
                        <>
                          <MdOutlineDragHandle className="h-6 w-6 m-0 hover:scale-125" />
                        </>
                      </SortableKnob>
                    </div>
                  </div>
                </SortableItem>
              ))}
            </SortableList>
          )}
        </Row>
      }
    />
  );
};

type SimManagementModalProps = {
  title?: string;
  modalName: string;
};

SimManagementModal.defaultProps = {
  title: "Sim Management",
};

export default SimManagementModal;

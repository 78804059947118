import { AppQueryConfig } from "QueryTypes";
import { clubhouseApiHeadersV1 } from "utils/api/apiHelper";
import { ScorecardTextType, ScorecardImageType } from "EntityTypes";

const urls = {
  scorecardText(simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/scorecardtext`;
  },
  scorecardImage(simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/scorecardimage`;
  },
  screenshotImage(simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/latest_screenshot`;
  },
};

export const getTextScorecardBySimQuery = (
  simulatorId: string
): AppQueryConfig => ({
  url: urls.scorecardText(simulatorId),
  queryKey: `getTextScorecardBySimQuery:${simulatorId}`,
  options: {
    headers: {
      ...clubhouseApiHeadersV1().headers,
    },
    method: "GET",
  },
  transform: (response: { data: string }) => {
    const obj: {
      [simulatorId: string]: string;
    } = { [simulatorId]: btoa(response.data) };
    return response.data === ""
      ? { scorecardTextStates: {} }
      : { scorecardTextStates: obj };
  },
  update: {
    scorecardTextStates: (oldValue, newValue): ScorecardTextType => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const getImageScorecardBySimQuery = (
  simulatorId: string
): AppQueryConfig => ({
  url: urls.scorecardImage(simulatorId),
  queryKey: `getImageScorecardBySimQuery:${simulatorId}`,
  options: {
    headers: {
      ...clubhouseApiHeadersV1().headers,
    },
    method: "GET",
  },
  transform: (response: { data: string }) => {
    const obj: {
      [simulatorId: string]: string;
    } = { [simulatorId]: response.data };
    return response.data === ""
      ? { scorecardImageStates: {} }
      : { scorecardImageStates: obj };
  },
  update: {
    scorecardImageStates: (oldValue, newValue): ScorecardImageType => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

import { useState } from "react";
import { DateTime } from "luxon";
import { motion } from "framer-motion";
import { FiEdit2 } from "react-icons/fi";
import { styles } from "../Leagues.styes";
import { BsPeople } from "react-icons/bs";
import { MdGroup } from "react-icons/md";
import { League, LeagueRound, ClubhouseRoundDto } from "Models";
import { useAppDispatch } from "utils/hooks";
import { titleCase } from "utils/enums/enumHelper";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { ModalType } from "utils/modals/modalHelpers";
import {
  LeagueHandicapMethod,
  LeagueRoundCompetitionType,
  LeagueScoring,
  LeagueType,
} from "types/enums";
import {
  getSelectedLeagueRound,
  useGetClubhouseLeagueRoundDto,
} from "modules/leagues/leagueRoundHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import ReactTooltip from "react-tooltip";
import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  useIsMatchLeague,
  useSelectedLeagueTypeIsTeam,
} from "modules/leagues/leagueHooks";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import LeagueRoundCardDetails from "./LeagueRoundCardDetails";
import LeagueRoundLeaderboardModal from "../LeagueRoundLeaderboardModal";
import LeagueRoundTeamLeaderboardModal from "../LeagueRoundTeamLeaderboardModal";
import MatchPlayRoundLeaderboardModal from "../MatchPlayRoundLeaderboardModal";
import MatchPlayTeamRoundLeaderboardModal from "../MatchPlayTeamRoundLeaderboardModal";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";

const LeagueRoundCard: React.FC<LeagueRoundCardProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const isTeamLeague = useSelectedLeagueTypeIsTeam();
  const isMatchPlayLeague = useIsMatchLeague();

  const { league, leagueRound } = props;
  const { leagueScoring } = useFlags();

  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [selectedRound, setSelectedRound] = useState<LeagueRound>();
  const [isOpen, setIsOpen] = useState(false);

  const [, , refreshLeagueRound] = useGetClubhouseLeagueRoundDto(
    currentFacilityId,
    league.id,
    leagueRound?.id
  );

  const isLeaderboardModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.LeagueRoundLeaderboard]?.isOpen
  );

  const handleEditLeagueRound = (leagueRoundId: string) => {
    let response;
    response = refreshLeagueRound();

    response?.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        dispatch(
          setSelectedRoundId({
            selectedLeagueRoundId: leagueRoundId,
          })
        );
        setIsOpen(false);
        dispatch(
          modalAction({ isOpen: true, modalName: ModalType.LeagueRoundsModal })
        );
      } else {
        showCustomNotification(
          getApiResponseErrorMessage(res),
          GenericNotificationType.ERROR
        );
      }
    });
  };

  const handleCreateRoundGroupsClick = () => {
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: leagueRound.id,
      })
    );
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.LeagueRoundGroups })
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_VIEW_GROUPS);
  };

  const handleViewRoundLeaderboardClick = () => {
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: leagueRound.id,
      })
    );
    setShowLeaderboard(!showLeaderboard);
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.LeagueRoundLeaderboard })
    );

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_VIEW_LEADERBOARD);
  };

  const handleGetLeagueRound = (roundId: string) => {
    const selectedRound = getSelectedLeagueRound(
      currentFacilityId,
      league.id,
      roundId
    );

    selectedRound.then((selectedRound) => {
      setSelectedRound(selectedRound);
      setIsOpen(!isOpen);
    });
  };

  return (
    <>
      <motion.div
        variants={containerChildMotionVariants}
        key={`league-${league?.id}-round-${leagueRound?.id}`}
        className=""
      >
        <div
          className={`${styles.leagueCard} hover:shadow-lg hover:rounded-xl hover:border-brandDarkGray leagueRoundCard`}
        >
          <div className={styles.leagueCardHeaderRow}>
            <div className="w-full h-8">
              <span className={`${styles.leagueTitle} mb-2 mt-1`}>
                {leagueRound.name}
              </span>
            </div>
            <div
              className={`${styles.leagueEditDeleteContainer} leagueRoundCardEditDelete`}
            >
              <button
                type="button"
                className={`${styles.leagueEditButton} mt-2`}
                data-for={`edit-${leagueRound.name}`}
                data-tip={`Edit ${leagueRound.name}`}
                onClick={() =>
                  handleEditLeagueRound(leagueRound?.id.toString())
                }
              >
                <FiEdit2 className={styles.leagueButtonIcon} />
              </button>
            </div>
          </div>
          <div className={styles.leagueCardHeaderRow}>
            <small className="rounded-lg bg-darkCream dark:bg-zinc-400 dark:text-black py-1 px-2 poppins h-6">
              <BsPeople className="h-4 w-4 mr-0.5" />
              {league.participantCount}
              {league.settings.leagueType === LeagueType.TEAM
                ? " teams"
                : " players"}
            </small>
            {leagueRound.numberOfGroups > 0 && (
              <small
                className={`ml-0.5 rounded-md px-2 pt-1 poppins h-6 dark:text-gray-50`}
              >
                <MdGroup className="h-4 w-4 mr-0.5 stroke-zinc-600 dark:stroke-gray-100" />
                {leagueRound.numberOfGroups} groups
              </small>
            )}
          </div>
          <div className={styles.leagueCardDateRow}>
            <div className={styles.leagueCardCol}>
              <div className={styles.titleRow}>Scoring Format</div>
              <div className={styles.titleDescription}>
                {titleCase(LeagueScoring[league?.settings.leagueScoringType])}
              </div>
              <div className={styles.titleRow}>Handicap Format</div>
              <div className={styles.titleDescription}>
                {titleCase(
                  LeagueHandicapMethod[league?.settings.handicapMethod]
                )}
              </div>
              <div className={styles.titleRow}>Competition Type</div>
              <div className={styles.titleDescription}>
                {league?.settings.leagueScoringType === LeagueScoring.SCRAMBLE
                  ? titleCase(LeagueScoring[league?.settings.leagueScoringType])
                  : titleCase(
                      LeagueRoundCompetitionType[leagueRound.competitionType]
                    )}
              </div>
            </div>
            <div className={styles.leagueCardCol}>
              <div className={styles.titleRow}>Start Date</div>
              <div className={`${styles.dateDescription} text-[10px]`}>
                {DateTime.fromISO(leagueRound.startPlayDate).toLocaleString({
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
              <div className={styles.titleRow}>End Date</div>
              <div className={styles.dateDescription}>
                {DateTime.fromISO(leagueRound.endPlayDate).toLocaleString({
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
              <div className={styles.titleRow}>Course</div>
              <div className={`${styles.titleDescription} h-10`}>
                {leagueRound.course}
              </div>
            </div>
          </div>

          {isOpen && selectedRound && (
            <LeagueRoundCardDetails selectedRound={selectedRound} />
          )}
          <div className={`${styles.leagueCardDetailsRow}`}>
            <div className={styles.leagueCardCol}>
              <div className="">
                <button
                  onClick={() =>
                    handleGetLeagueRound(leagueRound?.id.toString())
                  }
                  className="text-brandDarkGrey dark:text-gray-200 font-bold text-xs border-b border-gray-300"
                >
                  {isOpen ? "Close" : "See all"}
                </button>
              </div>
            </div>
          </div>
          <div className={styles.leagueCardRow}>
            <button
              className={styles.leagueViewRoundsButton}
              onClick={() => handleCreateRoundGroupsClick()}
            >
              Round Groups
            </button>
          </div>
          <div className={styles.leagueCardFooterRow}>
            <div className="w-full">
              {leagueScoring && (
                <button
                  type="button"
                  className={styles.leagueOtherButton}
                  onClick={() => handleViewRoundLeaderboardClick()}
                >
                  Leaderboard
                </button>
              )}
            </div>
          </div>
        </div>
        <ReactTooltip
          backgroundColor="#f5f5f5"
          textColor="#000"
          border={true}
          borderColor="#777777"
          id={`edit-${leagueRound.name}`}
          place="bottom"
        ></ReactTooltip>
      </motion.div>

      {showLeaderboard &&
        (isTeamLeague ? (
          isMatchPlayLeague ? (
            <MatchPlayTeamRoundLeaderboardModal
              callback={() => setShowLeaderboard(!showLeaderboard)}
              round={leagueRound}
            />
          ) : (
            <LeagueRoundTeamLeaderboardModal
              callback={() => setShowLeaderboard(!showLeaderboard)}
              round={leagueRound}
            />
          )
        ) : isMatchPlayLeague ? (
          <MatchPlayRoundLeaderboardModal
            callback={() => setShowLeaderboard(!showLeaderboard)}
            round={leagueRound}
          />
        ) : (
          isLeaderboardModalOpen && (
            <LeagueRoundLeaderboardModal
              callback={() => setShowLeaderboard(!showLeaderboard)}
              round={leagueRound}
            />
          )
        ))}
    </>
  );
};
export default LeagueRoundCard;

type LeagueRoundCardProps = {
  league: League;
  leagueRound: ClubhouseRoundDto;
  editLeague?: boolean;
};

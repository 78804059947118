import { motion } from "framer-motion";
import { useAppDispatch } from "utils/hooks";
import { styles } from "../../ui/layout/Header.styles";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useServiceRequestsByFacilityId } from "modules/service-requests/serviceRequestHooks";
import ServiceRequestIcon from "components/svg/ServiceRequestIcon";

const ServiceRequestAlertBySim: React.FC<Props> = (props) => {
  const { simulatorId } = props;
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [currentFacilityServiceReqests] = useServiceRequestsByFacilityId(
    currentFacilityId
  );

  const simulatorAlertsModalName = modalName(
    ModalType.SimulatorAlertsModal,
    simulatorId
  );

  const setModal = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const currentRequests = currentFacilityServiceReqests.filter((request) => {
    return request.simulatorId === simulatorId;
  }).length
    ? true
    : false;

  return (
    <div className="flex relative -top-1">
      <motion.button
        onClick={() => setModal(simulatorAlertsModalName)}
        variants={{
          pulse: {
            scale: [1, 1.05, 1, 1.05, 1, 1, 1, 1, 1],
          },
        }}
        animate={currentRequests ? "pulse" : ""}
        transition={{
          repeat: Infinity,
          repeatType: "loop",
          delay: 2,
          duration: 2,
        }}
        type="button"
        id="headerServiceRequestAlertButton"
        data-tip="Help Requests"
        data-for="headerButtonsTooltip"
      >
        <ServiceRequestIcon
          class={`${styles.buttonIconSm} ${
            currentRequests
              ? "text-red-500 dark:text-red-500"
              : "hidden brand-dark-gray"
          }`}
        />
      </motion.button>
      <span className={styles.buttonText}>Alerts</span>
    </div>
  );
};

export default ServiceRequestAlertBySim;

type Props = {
  title?: string;
  simulatorId: string;
};

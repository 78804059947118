import { RootState } from "StoreTypes";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { styles } from "./SingleSimListView.styles";
import { useRoundById } from "modules/round/roundHooks";

const SimListRoundInfo: React.FC<{ simulatorStateId: string }> = ({
  simulatorStateId,
}) => {
  const simulatorState = useSelector(
    (state: RootState) => state.entities?.simulatorStates?.[simulatorStateId]
  );
  const [currentRound] = useRoundById(simulatorState?.roundId);

  return (
    <>
      <div className="font-normal pt-1">
        {currentRound?.courseName ? (
          <span className={styles.span}>
            {"Course: "}
            {currentRound?.courseName}
            <br />
          </span>
        ) : null}
        {currentRound?.holesCompleted ? (
          <span className={styles.span}>
            {"Hole: "}
            <motion.span
              key={currentRound?.holesCompleted}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.75,
              }}
              className="inline"
            >
              {currentRound?.holesCompleted}
            </motion.span>{" "}
            {" of "}
            {currentRound?.holesOriginallySelected.filter(Boolean).length}
            {" completed"}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default SimListRoundInfo;

import { AnimatePresence, motion } from "framer-motion";
import { SimulatorDto } from "Models";
import {
  BreakPoints,
  containerMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import ListViewContainer from "./ListViewContainer";
import SingleSimCardView from "./SingleSimCardView";

type OwnProps = {
  allSelected: boolean;
  listView: boolean;
  simulators: SimulatorDto[];
  selectSim: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DashboardSimulatorsContainer: React.FC<OwnProps> = ({
  allSelected,
  listView,
  simulators,
  selectSim,
}) => {
  const isDesktop = useMediaQuery(BreakPoints.MD);

  return (
    <AnimatePresence>
      {simulators && (
        <motion.div
          className={
            !listView
              ? `grid gap-8 grid-cols-1 @2xl/control:grid-cols-2 @5xl/control:grid-cols-3 @7xl/control:grid-cols-4 px-2`
              : ""
          }
          variants={containerMotionVariants}
          initial="hidden"
          animate="show"
        >
          {!listView || !isDesktop ? (
            simulators?.map((sim) => (
              <SingleSimCardView
                simId={sim.id}
                key={`${sim.id}-${allSelected ? "on" : "off"}`}
                checkboxFunction={selectSim}
                checked={allSelected}
              />
            ))
          ) : (
            <ListViewContainer
              simulators={simulators}
              allSelected={allSelected}
              callback={selectSim}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DashboardSimulatorsContainer;

import Select from "react-select";
import styles from "./SimulatorReservationContainer.styles.";
import { SimulatorDto } from "Models";
import { useSelector } from "react-redux";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import { FormatOptionLabelContext } from "react-select/src/Select";

const SimulatorSelect: React.FC<SimulatorSelectProps> = (props) => {
  const {
    control,
    formState,
    setValue,
    clearErrors,
    getValues,
  } = useFormContext();
  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const { simulatorId } = props;

  const handleChange = (sim: SimulatorDto) => {
    setValue("simulatorId", sim.id);
    setValue("roundInfo.build", sim.caddyVersion);
    clearErrors("simulatorId");
  };

  const FormattedUserSearchOption = ({
    value,
    context,
  }: {
    value: SimulatorDto;
    context: FormatOptionLabelContext;
  }) => {
    return context === "menu" ? (
      <div className="brandHeading border-b border-gray-200 dark:border-white h-8 mx-4">
        {value.name}
      </div>
    ) : (
      <div>{value.name}</div>
    );
  };
  return (
    <>
      <span className={styles.dateSpan}>Select a Sim</span>
      <Controller
        name="simulatorId"
        control={control}
        rules={{ required: "Simulator is required" }}
        defaultValue={
          getValues("simulatorId") || simulatorId || simulators[0]?.id
        }
        render={({ field }) => (
          <Select
            {...field}
            closeMenuOnSelect={true}
            isMulti={false}
            options={simulators}
            getOptionLabel={(sim: SimulatorDto) => sim.name}
            getOptionValue={(sim: SimulatorDto) => sim.id}
            value={simulators.find((sim) => sim.id === field.value)}
            styles={customStyles}
            onChange={handleChange}
            formatOptionLabel={(
              sim: SimulatorDto,
              { context }: { context: FormatOptionLabelContext }
            ) => {
              return (
                <FormattedUserSearchOption value={sim} context={context} />
              );
            }}
          />
        )}
      />
      <ErrorMessage
        errors={formState.errors}
        name="simulatorId"
        render={({ message }) => (
          <div className="text-sm text-red-500">{message}</div>
        )}
      />
    </>
  );
};
export default SimulatorSelect;

type SimulatorSelectProps = {
  simulatorId?: string;
};

import { useAppDispatch } from "utils/hooks";
import { ButtonWithIcon } from "components/ui/form";
import { FormProvider, useForm } from "react-hook-form";
import {
  PlayerUser,
  RoundConfiguration,
  SimulatorReservationPayload,
} from "Models";
import {
  useCreateSimulatorReservation,
  useSelectedSimulatorReservation,
} from "modules/simulator-reservations/simulatorReservationHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import DateSelect from "./DateSelect";
import ReactTooltip from "react-tooltip";
import Row from "components/ui/layout/Row";
import SimulatorSelect from "./SimulatorSelect";
import StartGameForm from "./StartGameForm";
import { FaCloudUploadAlt } from "react-icons/fa";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { useStartRound } from "modules/round/roundHooks";
import { useEffect, useState } from "react";
import { playerUsersByLockerId } from "utils/userSearch/userSearchHelpers";
import SimulatorReservationPlayerCheck from "components/control/simulator-reservations/SimulatorReservationPlayerCheck";
import { useFacilityDefaultRoundSettings } from "modules/default-round-settings";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";
import RoundConfigurationSelect from "./RoundConfigurationSelect";
import { ArrayHelper } from "utils/arrays/arrayHelpers";
import SimulatorReservationSkeletonLoader from "./SimulatorReservationSkeletonLoader";

const SimulatorReservationContainer = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const [selectedSavedRound, setSavedRound] = useState<RoundConfiguration>();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedSimulatorReservation = useSelectedSimulatorReservation(
    currentFacilityId
  );
  const [defaultRoundSettings] = useFacilityDefaultRoundSettings(
    currentFacilityId
  );
  const [
    createSimulatorReservationQueryState,
    createSimulatorReservation,
  ] = useCreateSimulatorReservation(currentFacilityId);
  const methods = useForm();
  const [, startRound] = useStartRound(
    currentFacilityId,
    selectedSimulatorReservation?.simulatorId
  );
  const existingPlayers = methods.watch("roundInfo.players", []);

  const options = playerUsersByLockerId(
    selectedSimulatorReservation?.lockerAccounts
  );

  useEffect(() => {
    options
      .then((players: PlayerUser[]) => {
        if (isLoading) {
          players.map((player: PlayerUser) => {
            return existingPlayers &&
              !existingPlayers?.find(
                (existingPlayer: PlayerUser) =>
                  existingPlayer.username === player.username
              )
              ? methods.setValue(
                  "roundInfo.players",
                  existingPlayers.concat(player)
                )
              : null;
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [options, methods, existingPlayers, isLoading]);

  const onSubmit = async (data: SimulatorReservationPayload) => {
    startRound(data);
    pendoTrackEventWithProperties(ClubhouseEvents.CREATE_RESERVATION, {
      simulatorId: data.simulatorId,
      facilityId: currentFacilityId,
      courseName: data.roundInfo?.courseName,
      roundType: roundConfigEnumsAsReactSelectOptions.gameType.find(
        (option) => option.value === data.roundType
      )?.label,
      scoringType: roundConfigEnumsAsReactSelectOptions.scoringType.find(
        (option) => option.value === data.roundInfo?.scoringType
      )?.label,
      timer: data.time,
      players: data.roundInfo?.players?.length,
    });
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.RexReservationModal,
      })
    );
    showCustomNotification(`Reservation has been sent to sim.`);
  };

  const handleSaveForLater = async (data: SimulatorReservationPayload) => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }
    await createSimulatorReservation({
      ...data,
      facilityId: currentFacilityId,
    });
  };

  useEffect(() => {
    if (createSimulatorReservationQueryState.isFinished) {
      if (createSimulatorReservationQueryState.status === 200) {
        pendoTrackEventWithProperties(ClubhouseEvents.CREATE_RESERVATION, {
          simulatorId: methods.getValues("simulatorId"),
          facilityId: currentFacilityId,
          courseName: methods.getValues("roundInfo?.courseName"),
          roundType: roundConfigEnumsAsReactSelectOptions.gameType.find(
            (option) => option.value === methods.getValues("roundType")
          )?.label,
          scoringType: roundConfigEnumsAsReactSelectOptions.scoringType.find(
            (option) =>
              option.value === methods.getValues("roundInfo?.scoringType")
          )?.label,
          timer: methods.getValues("time"),
          players: methods.getValues("roundInfo?.players?.length"),
        });
        dispatch(
          modalAction({
            isOpen: false,
            modalName: ModalType.RexReservationModal,
          })
        );
        showCustomNotification(`Reservation has been added to the Queue.`);
      } else {
        showCustomNotification(
          "An error occurred. Please try again.",
          GenericNotificationType.ERROR
        );
      }
    }
  }, [
    methods,
    dispatch,
    createSimulatorReservationQueryState.isFinished,
    createSimulatorReservationQueryState.status,
    currentFacilityId,
  ]);

  useEffect(() => {
    if (selectedSimulatorReservation) {
      methods.setValue("time", selectedSimulatorReservation?.reservedDuration);
    }
  }, [methods, selectedSimulatorReservation]);

  useEffect(() => {
    if (selectedSavedRound) {
      methods.setValue("roundType", selectedSavedRound?.roundType);
      methods.setValue("roundInfo", selectedSavedRound?.roundInfo);
      methods.setValue("autoContinue", selectedSavedRound?.autoContinue);
      methods.setValue("rangeTime", selectedSavedRound?.rangeTime);
      methods.setValue(
        "holes",
        ArrayHelper.convertBooleanToNumberArray(
          selectedSavedRound?.roundInfo.holesOriginallySelected
        )
      );
    }
  }, [selectedSavedRound, methods]);

  useEffect(() => {
    if (defaultRoundSettings) {
      methods.setValue(
        "roundInfo.groundConditions",
        roundConfigEnumsAsReactSelectOptions?.groundConditions[2].value
      );
      methods.setValue(
        "roundInfo.windDirection",
        roundConfigEnumsAsReactSelectOptions?.windDirection[3].value
      );
      methods.setValue(
        "roundInfo.skyConditions",
        roundConfigEnumsAsReactSelectOptions?.weather[0].value
      );
      methods.setValue(
        "roundInfo.timeConditions",
        roundConfigEnumsAsReactSelectOptions?.timeOfDay[1].value
      );
      methods.setValue(
        "roundInfo.stimpSetting",
        roundConfigEnumsAsReactSelectOptions?.stimpSetting[1].value
      );
      methods.setValue(
        "roundInfo.mensTee",
        roundConfigEnumsAsReactSelectOptions?.teeType[2].value
      );
      methods.setValue(
        "roundInfo.womensTee",
        roundConfigEnumsAsReactSelectOptions?.teeType[0].value
      );
      methods.setValue(
        "roundInfo.autoDrop",
        defaultRoundSettings?.autoDrop || false
      );
      methods.setValue(
        "roundInfo.mulligans",
        defaultRoundSettings?.mulligans || false
      );
    }
  }, [methods, defaultRoundSettings]);

  return (
    <>
      {isLoading ? (
        <SimulatorReservationSkeletonLoader />
      ) : (
        <div className="flex w-full h-full flex-col">
          <Row cssClasses="border-b border-gray-300 pb-6">
            <RoundConfigurationSelect onChange={setSavedRound} />
          </Row>
          <Row cssClasses="mb-8">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <SimulatorSelect
                  simulatorId={selectedSimulatorReservation?.simulatorId}
                />
                <DateSelect
                  reservedDuration={
                    selectedSimulatorReservation?.reservedDuration
                  }
                />
                <SimulatorReservationPlayerCheck />
                <StartGameForm
                  simulatorReservation={selectedSimulatorReservation}
                  simulatorId={selectedSimulatorReservation?.simulatorId}
                  allowCreateLockerUser={true}
                />
                <ButtonWithIcon
                  buttonType="submit"
                  text="Play Now"
                  showIcon={false}
                  icon={FaCloudUploadAlt}
                  fullWidth={true}
                />
                <button
                  type="button"
                  className="mt-2 poppins text-sm bg-brandYellow hover:bg-brandDarkGrey hover:animate-pulse px-3 py-3 shadow-md rounded-lg hover:text-white w-full text-center disabled:cursor-not-allowed disabled:opacity-50"
                  disabled={false}
                  onClick={() =>
                    handleSaveForLater(
                      methods.getValues() as SimulatorReservationPayload
                    )
                  }
                >
                  Save For Later
                </button>
              </form>
            </FormProvider>
          </Row>
          <ReactTooltip backgroundColor="#777" textColor="#fff" />
        </div>
      )}
    </>
  );
};

export default SimulatorReservationContainer;

import { ValidationException, ProblemDetailsException } from "Models";
import { getCurrentUserToken } from "modules/user";

export const clubhouseApiHeadersV1 = (): {
  headers: { authorization: string; "API-Key": string };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "API-Key": `${process.env.REACT_APP_API_KEY}`,
  },
});

export const clubhouseApiHeadersV2 = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
  },
});
export const lockerApiHeaders = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
    "API-Key": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
    "API-Key": `${process.env.REACT_APP_API_KEY_LOCKER}`,
  },
});

export enum ApiResponseErrorType {
  ValidationException = "ValidationException",
  ProblemDetailsException = "ProblemDetailsException",
}

export enum ApiExceptionClassType {
  SCORECARD_NOT_FINALIZED = "error.scorecardnotfinalizedexception",
}

export const isApiStatusSuccess = (status: number | undefined) => {
  return status === 200 || status === 201 || status === 202 ? true : false;
};

export const getApiResponseErrorMessage = (response: any) => {
  if (
    response.type === ApiResponseErrorType.ValidationException ||
    response?.errors
  ) {
    return (response as ValidationException)?.errors[
      Object.keys(response?.errors)[0]
    ].toString();
  } else {
    return (response as ProblemDetailsException)?.detail;
  }
};

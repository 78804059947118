import { FacilityInstalledUnityGames, SimulatorDto } from "Models";
import { AppQueryConfig } from "QueryTypes";
import { normalize } from "normalizr";
import { facilityAndSimSchema } from "../facility/facilitySchema";
import { SimulatorsEntitiesState } from "StoreTypes";
import { clubhouseApiHeadersV2 } from "utils/api/apiHelper";

const urls = {
  installedUnityGames(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/unity/facility/${facilityId}/installed_games`;
  },
  updateFacilitySimulators(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulators`;
  },
  updateFacilitySimulatorName(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulators/names`;
  },
  updateSimulatorScreenshots(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulators/screenshots`;
  },
};

export const facilityInstalledUnityGamesQuery = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.installedUnityGames(facilityId),
  queryKey: `facilityInstalledUnityGamesQuery:${facilityId}`,
  options: {
    ...clubhouseApiHeadersV2(),
    method: "GET",
  },
  transform: (responseJson: FacilityInstalledUnityGames) => {
    return {
      installedUnityGames: {
        byFacilityId: {
          [facilityId]: responseJson,
        },
      },
    };
  },
  update: {
    installedUnityGames: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const updateFacilitySimulatorsOrderQuery = (
  facilityId: string,
  body: {
    id: string;
    name: string;
  }[]
): AppQueryConfig => ({
  url: urls.updateFacilitySimulators(facilityId),
  options: {
    ...clubhouseApiHeadersV2(),
    method: "PATCH",
  },
  body,
  transform: (responseJson: SimulatorDto[]) => {
    return normalize(responseJson, [facilityAndSimSchema]).entities;
  },
  update: {
    simulators: (oldValue, newValue): SimulatorsEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const updateFacilitySimulatorNameQuery = (
  facilityId: string,
  body: {
    id: string;
    name: string;
  }[]
): AppQueryConfig => ({
  url: urls.updateFacilitySimulatorName(facilityId),
  options: {
    ...clubhouseApiHeadersV2(),
    method: "PUT",
  },
  body,
  transform: (responseJson: SimulatorDto[]) => {
    return normalize(responseJson, [facilityAndSimSchema]).entities;
  },
  update: {
    simulators: (oldValue, newValue): SimulatorsEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

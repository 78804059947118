import { useSelector } from "react-redux";
import RoundProgressBarSlim from "components/shared/control/RoundProgressBarSlim";
import {
  getScreenshotBySimId,
  getSimulatorBySimId,
} from "modules/simulator/simulatorSelectors";
import { RootState } from "StoreTypes";
import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import styles from "../SingleFacilityControlDashboard.styles";
import ScorecardModal from "./ScorecardModal";
import { createContext, useEffect, useMemo, useState } from "react";
import {
  simulatorStateIsActive,
  isSimulatorOff,
  isActive,
} from "modules/simulator-state/simulatorStateHelpers";
import { isModalOpen, modalName, ModalType } from "utils/modals/modalHelpers";
import SimulatorCardOverlay from "./SimulatorCardOverlay";
import RoundControlModal from "../round-control/RoundControlModal";
import PlayerListIcons from "components/shared/control/PlayerListIcons";
import SimTimerCard from "./timers/SimTimerCard";
import SimStatusCard from "./SimStatusCard";
import AddTimeInnerModal from "./AddTimeInnerModal";
import SingleSimCardViewFooter from "./SingleSimCardViewFooter";
import RateOfPlay from "./RateOfPlay";
import SendMessageInnerModal from "./SendMessageInnerModal";
import { motion } from "framer-motion";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import ShutdownAndRestartButtons from "./ShutdownAndRestartButtons";
import MoveRoundInnerModal from "./MoveRoundInnerModal";
import ScorecardLinks from "./ScorecardLinks";
import LiveScreenshots from "./LiveScreenshots";
import LatestScreenshotModal from "./LatestScreenshotModal";
import UpcomingReservations from "./UpcomingReservations";
import SimulatorAlertsInnerModal from "./SimulatorAlertsInnerModal";
import ServiceRequestAlertBySim from "./ServiceRequestAlertBySim";

type CardType = {
  cardIcon?: string;
  cardTitle?: string;
  cardStatus?: string;
  cardClass?: string;
  checked?: boolean;
  checkboxFunction?: any;
  simId: string;
};

export const SimCardContext: React.Context<{
  simulatorId: string;
}> = createContext({
  simulatorId: "",
});

const SingleSimCardView: React.FC<CardType> = ({ cardClass, simId }) => {
  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: simId })
  );
  const screenshot = useSelector((state: RootState) =>
    getScreenshotBySimId(state, { simId: simId })
  );

  const [simulatorState, simulatorStateQueryStatus] = useSimulatorState(simId);

  const stateIsActive = simulatorStateIsActive(simulatorState);
  const roundIsActive = useMemo(() => {
    return isActive(simulatorState);
  }, [simulatorState]);
  const simulatorTurnedOff = isSimulatorOff(simulatorState);

  const [roundInProgress, setRoundInProgress] = useState(roundIsActive);

  const simControlModalName = modalName(ModalType.SimControlModal, simId);
  const roundControlModalName = modalName(ModalType.RoundControlModal, simId);
  const scorecardModalName = modalName(ModalType.ScorecardModal, simId);
  const screenshotModalName = modalName(ModalType.LatestScreenshotModal, simId);
  const moveRoundModalName = modalName(ModalType.MoveRoundModal, simId);
  const simulatorAlertsModalName = modalName(
    ModalType.SimulatorAlertsModal,
    simId
  );
  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    simId
  );

  useEffect(() => {
    setRoundInProgress(roundIsActive);
  }, [roundIsActive]);

  return (
    <SimCardContext.Provider value={{ simulatorId: simId }}>
      <motion.div
        className={`${cardClass} ${styles.simCardContainer} relative`}
        variants={containerChildMotionVariants}
        key={`singleSimCard-${simId}`}
      >
        <SimulatorCardOverlay
          simulatorId={simId}
          showOverlay={simulatorTurnedOff}
          showButton={false}
          showIcon={false}
          showLoadingAnimation={
            simulatorStateQueryStatus?.isFinished === true ? false : true
          }
        />
        {isModalOpen(roundControlModalName) && (
          <RoundControlModal
            title="Round Control"
            modalName={roundControlModalName}
            simulatorId={simId}
            buildId={simulator?.caddyVersion}
          />
        )}
        {isModalOpen(scorecardModalName) && (
          <ScorecardModal
            title="View Scorecard"
            modalName={scorecardModalName}
            simulatorId={simId}
          />
        )}
        {isModalOpen(screenshotModalName) && (
          <LatestScreenshotModal
            title="Latest Screenshot"
            modalName={screenshotModalName}
            simulatorId={simId}
          />
        )}
        {isModalOpen(simControlModalName) && (
          <AddTimeInnerModal
            simControlModalName={simControlModalName}
            simulatorState={simulatorState}
            listView={false}
          />
        )}
        {isModalOpen(sendSimMessageModalName) && (
          <SendMessageInnerModal
            simulatorState={simulatorState}
            modalName={sendSimMessageModalName}
          />
        )}
        {isModalOpen(moveRoundModalName) && (
          <MoveRoundInnerModal
            modalName={moveRoundModalName}
            simulatorId={`${simId}`}
            simulatorName={simulator?.name || ""}
            roundId={simulatorState?.roundId}
          />
        )}
        {isModalOpen(simulatorAlertsModalName) && (
          <SimulatorAlertsInnerModal
            modalName={simulatorAlertsModalName}
            simulatorId={simId}
          />
        )}
        <div
          className={`${stateIsActive ? styles.active : styles.inactive} ${
            styles.simHeaderContainer
          } `}
        >
          <div className="flex flex-col w-full border-b border-gray-200 dark:border-zinc-900 px-4 py-2">
            <div className="flex flex-row w-full py-1">
              <div className="flex">{simulator?.name}</div>
              <div className="flex place-items-end justify-end ml-auto w-8">
                <ServiceRequestAlertBySim simulatorId={simulator?.id} />
              </div>
            </div>
            <div className="flex flex-row w-full align-middle justify-items-center">
              <div className="flex w-1/2 justify-start items-center align-middle">
                {stateIsActive && <ScorecardLinks simId={simulatorState?.id} />}
              </div>
              <div className="flex w-1/2 items-end justify-end justify-items-end align-middle ml-auto">
                {simulatorState?.estimatedTimeRemaining && (
                  <RateOfPlay simulatorState={simulatorState} />
                )}
              </div>
            </div>
          </div>
        </div>
        {roundInProgress ? (
          <RoundProgressBarSlim
            simulatorId={simId}
            secondaryColor="bg-lime-100"
            height="h-1"
          />
        ) : (
          <div
            className={`h-0.5 ${
              stateIsActive ? styles.active : styles.inactive
            }`}
          ></div>
        )}
        {screenshot?.image &&
        simulator?.status?.screenShotOn &&
        stateIsActive ? (
          <LiveScreenshots
            screenshot={screenshot}
            simulatorState={simulatorState}
            simulatorId={simId}
          />
        ) : (
          <>
            <div
              className={`flex flex-row ${
                stateIsActive ? styles.active : styles.inactive
              }`}
            >
              <div className={styles.singleSimCardViewBody}>
                <ShutdownAndRestartButtons simulatorId={simulatorState?.id} />
                <div className={styles.singleSimCardViewSimStatus}>
                  <SimStatusCard simulatorState={simulatorState} />
                </div>
                <div className={styles.singleSimCardViewPlayerRow}>
                  <PlayerListIcons
                    showNames="hidden"
                    iconSize="w-7 h-7"
                    roundId={simulatorState?.roundId}
                  />
                </div>
              </div>
              <div className={styles.singleSimCardViewTimerColumn}>
                <SimTimerCard simulatorState={simulatorState} />
              </div>
            </div>

            <div className="flex flex-row h-10 text-xs bg-white dark:bg-zinc-800 ml-6">
              <UpcomingReservations simulatorId={simulator?.id} />
            </div>
          </>
        )}

        <div
          className={`${styles.singleSimCardViewFooterRow} ${
            stateIsActive ? styles.active : styles.inactive
          }`}
        >
          {simulator && (
            <SingleSimCardViewFooter
              simulator={simulator}
              roundInProgress={roundInProgress}
              roundIsActive={roundIsActive}
              stateIsActive={stateIsActive}
            />
          )}
        </div>
      </motion.div>
    </SimCardContext.Provider>
  );
};

export default SingleSimCardView;

SingleSimCardView.defaultProps = {
  cardIcon: "",
  cardTitle: "",
  cardStatus: "",
  cardClass: "",
  checked: false,
};

import { RootState } from "StoreTypes";
import { FacilityNormalized } from "EntityTypes";
import { FacilitySettings } from "Models";

export const getCurrentFacilityId = (state: RootState): string =>
  state.facility.currentFacilityId;

export const getCurrentFacility = (state: RootState): FacilityNormalized =>
  state.entities.facilities?.[state.facility.currentFacilityId];

export const getFacilityById = (
  state: RootState,
  { facilityId }: { facilityId: string }
) => state.entities.facilities?.[facilityId];

export const getCurrentFacilitySettings = (
  state: RootState
): FacilitySettings =>
  state.entities.facilities?.[state.facility.currentFacilityId].settings;

import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./SingleSimListView.styles";
import { modalAction } from "ui-modules/modals/modalSlice";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import RoundProgressBarSlim from "components/shared/control/RoundProgressBarSlim";
import ScorecardModal from "./ScorecardModal";
import SimListRoundInfo from "./SimListRoundInfo";
import {
  isActive,
  simulatorStateIsActive,
} from "modules/simulator-state/simulatorStateHelpers";
import { isModalOpen, modalName, ModalType } from "utils/modals/modalHelpers";
import SimEndRoundButton from "components/shared/control/SimEndRoundButton";
import RoundControlModal from "../round-control/RoundControlModal";
import PlayerListIcons from "components/shared/control/PlayerListIcons";
import ShowLoadingAnimation from "./ShowLoadingAnimation";
import Timer from "./timers/Timer";
import ScorecardLinks from "./ScorecardLinks";
import ReactTooltip from "react-tooltip";
import RateOfPlay from "./RateOfPlay";
import TimerIconControls from "./timers/TimerIconControls";
import AddTimeInnerModal from "./AddTimeInnerModal";
import { motion } from "framer-motion";
import { BiArrowToRight, BiMessageDetail } from "react-icons/bi";
import SendMessageInnerModal from "./SendMessageInnerModal";
import MoveRoundInnerModal from "./MoveRoundInnerModal";
import SimStatusName from "./SimStatusName";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import ShutdownAndRestartButtons from "./ShutdownAndRestartButtons";
import { IoImageOutline, IoPlayOutline } from "react-icons/io5";
import SimulatorAlertsInnerModal from "./SimulatorAlertsInnerModal";
import ServiceRequestAlertBySim from "./ServiceRequestAlertBySim";
import { useFlags } from "launchdarkly-react-client-sdk";
import LatestScreenshotModal from "./LatestScreenshotModal";

const SingleSimListView: React.FC<RowType> = ({ simId, checked }) => {
  const dispatch = useAppDispatch();
  const { liveScreenshots } = useFlags();
  const [simulatorState] = useSimulatorState(simId);
  const [, setChecked] = useState(false);

  const simControlModalName = modalName(ModalType.SimControlModal, simId);
  const roundControlModalName = modalName(ModalType.RoundControlModal, simId);
  const scorecardModalName = modalName(ModalType.ScorecardModal, simId);
  const moveRoundModalName = modalName(ModalType.MoveRoundModal, simId);
  const screenshotModalName = modalName(ModalType.LatestScreenshotModal, simId);
  const simulatorAlertsModalName = modalName(
    ModalType.SimulatorAlertsModal,
    simId
  );
  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    simId
  );

  const roundIsActive = useMemo(() => {
    return isActive(simulatorState);
  }, [simulatorState]);
  const [roundInProgress, setRoundInProgress] = useState(roundIsActive);

  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: simId })
  );

  const setModal = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const stateIsActive = simulatorStateIsActive(simulatorState);

  useEffect(() => {
    if (checked && stateIsActive) {
      setChecked(true);
    }
  }, [checked, simulatorState?.status, stateIsActive]);

  useEffect(() => {
    setRoundInProgress(roundIsActive);
  }, [roundIsActive]);

  return (
    <motion.div
      className="flex flex-col flex-auto w-full dark:hover-zinc-800 my-1"
      variants={containerChildMotionVariants}
      key={`singleSimList-${simId}`}
      id={`singleSimList-${simId}`}
    >
      {isModalOpen(roundControlModalName) && (
        <RoundControlModal
          title="Round Control"
          modalName={roundControlModalName}
          simulatorId={simId}
          buildId={simulator?.caddyVersion}
        />
      )}
      {isModalOpen(scorecardModalName) && (
        <ScorecardModal
          title="View Scorecard"
          modalName={scorecardModalName}
          simulatorId={simId}
        />
      )}
      <div
        className={`bg-white dark:bg-zinc-800 text-gray-800 dark:text-gray-200 border-gray-200 dark:border-zinc-900 border relative flex flex-row w-full rounded-xl gap-2 px-2 sm:px-6 ${
          isModalOpen(simControlModalName) ||
          isModalOpen(sendSimMessageModalName) ||
          isModalOpen(moveRoundModalName) ||
          isModalOpen(simulatorAlertsModalName)
            ? "h-56"
            : "h-22"
        }`}
      >
        {isModalOpen(simControlModalName) && (
          <AddTimeInnerModal
            simControlModalName={simControlModalName}
            simulatorState={simulatorState}
            listView={true}
          />
        )}
        {isModalOpen(sendSimMessageModalName) && (
          <SendMessageInnerModal
            simulatorState={simulatorState}
            modalName={sendSimMessageModalName}
          />
        )}
        {isModalOpen(moveRoundModalName) && (
          <MoveRoundInnerModal
            modalName={moveRoundModalName}
            simulatorId={`${simId}`}
            simulatorName={simulator?.name || ""}
            roundId={simulatorState?.roundId}
            listView={true}
          />
        )}
        {isModalOpen(screenshotModalName) && (
          <LatestScreenshotModal
            title="Latest Screenshot"
            modalName={screenshotModalName}
            simulatorId={simId}
          />
        )}
        {isModalOpen(simulatorAlertsModalName) && (
          <SimulatorAlertsInnerModal
            modalName={simulatorAlertsModalName}
            simulatorId={simId}
          />
        )}
        <div className={`${styles.listViewColumn} text-gray-900`}>
          <div className="flex flex-row items-center pr-2"></div>
          <div className="flex flex-col">
            <span className="font-bold poppins">{simulator?.name}</span>
            {stateIsActive && <ScorecardLinks simId={simulatorState?.id} />}
          </div>
        </div>
        <div className={`${styles.listViewColumnHideOnSmall} `}>
          <div>
            <PlayerListIcons
              showNames="hidden"
              iconSize="w-8 h-8"
              roundId={simulatorState?.roundId}
            />
          </div>

          {simulatorState ? null : <ShowLoadingAnimation showListView={true} />}
        </div>
        <div className={`${styles.listViewColumnHideOnSmall} `}>
          {roundInProgress ? (
            <div>
              <SimStatusName simulatorState={simulatorState} />
              <div className="hidden @5xl/listview:flex">
                <SimListRoundInfo simulatorStateId={simulatorState?.id} />
              </div>
            </div>
          ) : (
            <SimStatusName simulatorState={simulatorState} />
          )}
        </div>
        <div className={`${styles.listViewColumn}`}>
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex text-xs">
                <Timer simulatorId={simulatorState ? simulatorState.id : ""} />
              </div>
              {simulatorState && (
                <div className="flex text-sm text-gray-400">
                  <TimerIconControls simulatorState={simulatorState} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.listViewColumn}`}>
          {simulatorState && (
            <div className="flex flex-col text-xs text-gray-600 dark:text-gray-50">
              <div className="mr-2">
                {simulatorState?.estimatedTimeRemaining && (
                  <RateOfPlay simulatorState={simulatorState} />
                )}
                <div className="mt-2">
                  <RoundProgressBarSlim simulatorId={simulatorState.id} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`${styles.listViewEditColumn}`}>
          <div className="flex place-items-end justify-end">
            <div className="mr-2">
              <ServiceRequestAlertBySim simulatorId={simulator?.id} />
            </div>
            <ShutdownAndRestartButtons simulatorId={simulatorState?.id} />
          </div>
          <div className="flex place-items-end justify-end mt-0.5">
            {roundInProgress ? (
              <SimEndRoundButton
                simulatorId={simId}
                className={`${roundIsActive ? "disabled" : ""} p-1 h-6 w-6`}
                showIcon={true}
                showText={false}
              />
            ) : (
              <button
                disabled={!stateIsActive}
                onClick={() => setModal(`roundControlModal-${simId}`)}
                className={`${roundIsActive ? "disabled" : ""}`}
                data-tip="Load Round"
                data-testid="loadRoundListViewButton"
              >
                <IoPlayOutline className="flex h-6 w-6 text-center p-1 items-center text-sm text-gray-400 hover:text-gray-700 dark:hover:bg-zinc-400 active:shadow-lg cursor-pointer size-7 rounded-full border border-gray-200 dark:border-zinc-900 bg-white dark:bg-zinc-500 brand-gray dark:text-black" />
              </button>
            )}
            <button
              onClick={() => setModal(sendSimMessageModalName)}
              className="mx-1"
              data-tip="Send Message"
            >
              <BiMessageDetail className="flex h-6 w-6 text-center p-1 items-center text-sm text-gray-400 hover:text-gray-700 dark:hover:bg-zinc-400 active:shadow-lg cursor-pointer size-7 rounded-full border border-gray-200 dark:border-zinc-900 bg-white dark:bg-zinc-500 brand-gray dark:text-black" />
            </button>
            {liveScreenshots && simulator?.status.screenShotOn && (
              <button
                onClick={() => setModal(screenshotModalName)}
                data-for={`singleSimCardViewFooter-${simulator?.id}`}
                data-tip={`View Latest Screenshot`}
              >
                <IoImageOutline className="flex h-6 w-6 text-center p-1 items-center text-sm text-gray-400 hover:text-gray-700 dark:hover:bg-zinc-400 active:shadow-lg cursor-pointer size-7 rounded-full border border-gray-200 dark:border-zinc-900 bg-white dark:bg-zinc-500 brand-gray dark:text-black" />
              </button>
            )}
            <button
              disabled={!stateIsActive && !roundInProgress}
              onClick={() => setModal(moveRoundModalName)}
              data-tip="Move Round"
            >
              <BiArrowToRight className="flex h-6 w-6 text-center p-1 items-center text-sm text-gray-400 hover:text-gray-700 dark:hover:bg-zinc-400 active:shadow-lg cursor-pointer size-7 rounded-full border border-gray-200 dark:border-zinc-900 bg-white dark:bg-zinc-500 brand-gray dark:text-black" />
            </button>

            <ReactTooltip place="bottom" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default SingleSimListView;

type RowType = {
  cardIcon?: string;
  cardTitle?: string;
  cardStatus?: string;
  cardClass?: string;
  checked?: boolean;
  checkboxFunction?: any;
  simId: string;
};

import { MdClose } from "react-icons/md";
import { useAppDispatch } from "utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { isModalOpen } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useServiceRequestsByFacilityId } from "modules/service-requests/serviceRequestHooks";
import ServiceRequestContainer from "./ServiceRequestContainer";

const SimulatorAlertsInnerModal: React.FC<SendMessageInnerModalProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const { modalName, simulatorId } = props;

  const [currentFacilityServiceReqests] = useServiceRequestsByFacilityId(
    currentFacilityId
  );

  const setModalClose = () => {
    dispatch(modalAction({ isOpen: false, modalName: modalName }));
  };

  return (
    <>
      {isModalOpen(modalName) && (
        <AnimatePresence>
          <motion.div
            className="z-9999 h-full w-full border-1 rounded-xl border-gray-400 absolute bg-white dark:bg-zinc-800"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{
              width: 0,
              opacity: 0.7,
              transition: { delay: 0.15, duration: 0.3 },
            }}
            key={`sendMessageInnerModal-${modalName}`}
          >
            <div className="relative flex flex-row w-full items-end justify-end pr-4 mt-2 overflow-hidden h-6 pt-4 mr-2">
              <button
                onClick={() => setModalClose()}
                className="z-10 flex gap-2 font-bold right-0 top-1 w-6 h-6"
              >
                <MdClose className="w-6 h-6 text-black dark:text-white" />
              </button>
            </div>
            <div className="pb-4 dark:text-white text-xs font-semibold ml-4">
              Click/swipe left on alert to dismiss.
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={`addSimulatorAlertsInnerModal-${modalName}`}
            >
              <div className="flex flex-col m-2 p-2 overflow-y-scroll bg-gray-100 dark:bg-zinc-900 rounded-xl scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100 dark:scrollbar-track-zinc-900">
                {currentFacilityServiceReqests
                  .filter((request) => {
                    return request.simulatorId === simulatorId;
                  })
                  .map((request) => (
                    <div className="w-full inline-block">
                      <ServiceRequestContainer
                        serviceRequest={request}
                        key={request.id}
                      />
                    </div>
                  ))}
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};
export default SimulatorAlertsInnerModal;

type SendMessageInnerModalProps = {
  modalName: string;
  simulatorId: string;
};

import { SimulatorDto, SimulatorState } from "Models";
import { RootState } from "StoreTypes";

export const getSimulatorStatesForArrayOfSims = (
  state: RootState,
  simulators: SimulatorDto[]
): SimulatorState[] => {
  let result: SimulatorState[] = [];
  simulators.forEach((sim) => {
    if (state.entities.simulatorStates?.[sim.id]) {
      result.push(state.entities.simulatorStates?.[sim.id]);
    }
  });
  return result;
};

export const getSimulatorStatesBySimIdsArray = (
  state: RootState,
  simIds: string[]
): SimulatorState[] => {
  let result: SimulatorState[] = [];
  simIds.forEach((simId) => {
    if (state.entities.simulatorStates?.[simId]) {
      result.push(state.entities.simulatorStates?.[simId]);
    }
  });
  return result;
};

import Timer from "./timers/Timer";
import SimStatusCard from "./SimStatusCard";
import notificationMp3 from "notification.mp3";
import TimerIconControls from "./timers/TimerIconControls";
import ShutdownAndRestartButtons from "./ShutdownAndRestartButtons";
import UpcomingReservations from "./UpcomingReservations";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { updateEntities } from "redux-query";
import { useAppDispatch } from "utils/hooks";
import { ServiceRequestType } from "types/enums";
import { RootState, ServiceRequestEntityState } from "StoreTypes";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  ServiceRequest,
  SimulatorScreenShotsDto,
  SimulatorState,
} from "Models";
import { getCurrentFacilitySettings } from "modules/facility/facilitySelectors";
import { useServiceRequestsByFacilityId } from "modules/service-requests/serviceRequestHooks";

type OwnProps = {
  screenshot: SimulatorScreenShotsDto;
  simulatorState: SimulatorState;
  simulatorId: string;
};

const LiveScreenshots: React.FC<OwnProps> = (props) => {
  const { screenshot, simulatorState, simulatorId } = props;

  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const screenshotNotification = useSelector((state: RootState) =>
    getCurrentFacilitySettings(state)
  )?.screenShotNotification;

  const [currentFacilityServiceReqests] = useServiceRequestsByFacilityId(
    currentFacilityId
  );

  useEffect(() => {
    function handleScreenshotNotification() {
      const notification = new Audio(notificationMp3);
      const playSound = () => {
        notification.play();
      };
      if (
        currentFacilityServiceReqests.filter((request) => {
          return (
            request.simulatorId === simulatorId &&
            request.type === ServiceRequestType.CC_LIVE_SCREENSHOT_HELP
          );
        }).length === 0
      ) {
        let requestId = uuidv4().toString();
        let obj: {
          [requestId: string]: ServiceRequest;
        } = {};
        obj[requestId] = {
          id: requestId,
          facilityId: currentFacilityId,
          simulatorId: simulatorId,
          type: ServiceRequestType.CC_LIVE_SCREENSHOT_HELP,
          createdDate: DateTime.now().toString(),
          dismissed: false,
        };
        playSound();
        dispatch(
          updateEntities({
            serviceRequestsByFacilityId: (prev: ServiceRequestEntityState) => ({
              ...prev,
              ...{
                [currentFacilityId]: { ...prev[currentFacilityId], ...obj },
              },
            }),
          })
        );
      }
    }

    const interval = setInterval(() => {
      if (
        DateTime.now().diff(
          DateTime.fromISO(screenshot.captureTimeUtc, {
            setZone: true,
          }),
          ["seconds"]
        ).seconds > screenshotNotification
      ) {
        handleScreenshotNotification();
      }
    }, (screenshotNotification + 1) * 1000);

    return () => clearInterval(interval);
  }, [
    currentFacilityId,
    currentFacilityServiceReqests,
    dispatch,
    screenshot,
    screenshotNotification,
    simulatorId,
  ]);

  return (
    <>
      <div
        className={`flex flex-row h-48 relative bg-contain @2xl/control:bg-cover @5xl/control:bg-contain`}
        style={{
          backgroundImage: `url(data:image/jpg;base64,${screenshot?.image})`,
          backgroundPosition: "center",
          aspectRatio: `${screenshot?.width} / ${screenshot?.height}`,
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
        }}
      >
        <div className="rounded-br-xl bg-white dark:bg-zinc-800 opacity-50 hover:opacity-95 border border-gray-200 dark:border-zinc-900 p-2 absolute">
          <ShutdownAndRestartButtons simulatorId={simulatorState?.id} />
        </div>
        <div className="flex w-full h-auto content-end text-xs m-auto justify-center mb-2">
          <UpcomingReservations simulatorId={simulatorId} />
        </div>
      </div>
      <div className="flex flex-row p-2 bg-white dark:bg-zinc-800">
        <div className="flex w-1/2 text-xs ml-2 items-center justify-items-center">
          <SimStatusCard
            simulatorState={simulatorState}
            hideStatusHeader={true}
          />
        </div>
        <div className="flex w-1/4 text-xs poppins items-center text-center justify-center text-gray-400 mr-1">
          <Timer simulatorId={simulatorState ? simulatorState.id : ""} />
        </div>
        {simulatorState && (
          <div className="flex w-1/4 text-sm text-gray-400 m-auto justify-items-end">
            <TimerIconControls simulatorState={simulatorState} />
          </div>
        )}
      </div>
    </>
  );
};

export default LiveScreenshots;
